body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  /* overflow: hidden; */
  font-family: 'ibm-plex-sans', Helvetica Neue, Arial, sans-serif;
  /* text-rendering: optimizelegibility; */
  background-color: var(--bg);
  color: var(--brightText);
  --error: #da1e28;
  --blue: #0062ff;
  --blueDisabled: #0062ff51;
  --blueGradient: linear-gradient(
    to right,
    #0062ff,
    #0071ff,
    #007fff,
    #008cff,
    #0098ff,
    #0098ff,
    #0098ff,
    #0098ff,
    #008cff,
    #007fff,
    #0071ff,
    #0062ff
  );
  --blue-hover: #0353e9;
  --font-size-14: 14px;
  --appBarHeight: 64px;
  --sideBarWidth: 260px;
  --paginatorHeight: 50px;
  --pageTitleTopPadding: calc(var(--appBarHeight) + 0.5vw);
  --pageHorizontalPadding: calc(15px + 5%);
  --selectionBarHeight: 44px;
  --sideBarAnnotationWidth: 210px;
}

body.dark {
  --selectionBar: #393939;
  --selectionBarHover: #4c4c4c;
  --selectionBarBorder: transparent;
  --selectionBarOutline: #ffffff;

  --primaryLink: #78a9ff;
  --primaryLinkHover: #a6c8ff;
  --primaryLinkHoverBG: #353535;

  --skeleton1: #353535;
  --skeleton2: #525252;

  --success: #42be65;
  --training: #f1c21b;
  --canceled: #6f6f6f;
  --failed: #fa4d56;

  --form-bg: #393939;
  --form-text: #f4f4f4;

  --select-icon: #f4f4f4;
  --popup-bg: #262626;
  /* --popup-overlay: rgba(38, 38, 38, 0.7); */
  --popup-overlay: rgba(22, 22, 22, 0.93);

  --dissabledButton: rgba(255, 255, 255, 0.12);
  --dissabledButtonText: rgba(255, 255, 255, 0.2);

  --secondaryButton: #6f6f6f;
  --secondaryButtonHover: #606060;

  --images: url('common/EmptySet/imagesdark.png');

  --dropzoneGrad1: hsla(0, 0%, 0%, 1);
  --dropzoneGrad2: hsla(0, 0%, 0%, 0.85);

  --skeleton: rgba(255, 255, 255, 0.1);

  --textInput: #282828;
  --textInputUnderline: #606060;

  --dropDownBorder: #414141;
  --listDivider: var(--dropDownBorder);

  --toolBarSpacer: #000000;

  --toolColor: #ffffff;
  --textInputBack: #21282a;
  --activeHighlight: var(--blue);
  --default-font-weight: normal;
  --darkBg: #0a0a0a;
  --bg: #111417;
  --border: transparent;
  --secondaryBg: #181c1e;
  --appBar: #1b1f21;
  --appBar2: #1f2426;
  --appBarShadow: var(--bg);
  --brightText: rgba(255, 255, 255, 0.9);
  --secondaryText: rgba(255, 255, 255, 0.87);
  --detailText: rgba(255, 255, 255, 0.53);
  --disabledText: rgba(255, 255, 255, 0.2);
  --ultraDim: rgba(255, 255, 255, 0.08);
  --highlight: #26292a;
  --highlight2: #394347;

  --progressBg: rgba(255, 255, 255, 0.53);
}

body.light {
  --selectionBar: white;
  --selectionBarHover: #e5e5e5;
  --selectionBarBorder: #dadce0;
  --selectionBarOutline: #0f62fe;

  --primaryLink: #0f62fe;
  --primaryLinkHover: #0043ce;
  --primaryLinkHoverBG: #e5e5e5;

  --skeleton1: #e5e5e5;
  --skeleton2: #c6c6c6;

  --success: #24a148;
  --training: #f1c21b;
  --canceled: #8d8d8d;
  --failed: #da1e28;

  --form-bg: #ffffff;
  --form-text: #161616;

  --select-icon: #161616;
  --popup-bg: #f4f4f4;
  --popup-overlay: rgba(22, 22, 22, 0.5);

  --dissabledButton: rgba(0, 0, 0, 0.12);
  --dissabledButtonText: rgba(0, 0, 0, 0.2);

  --secondaryButton: #3d3d3d;
  --secondaryButtonHover: #4c4c4c;

  --images: url('common/EmptySet/imageslight.png');

  --dropzoneGrad1: hsla(0, 0%, 100%, 1);
  --dropzoneGrad2: hsla(0, 0%, 100%, 0.85);

  --skeleton: rgba(0, 98, 255, 0.1);

  --textInput: #f3f3f3;
  --textInputUnderline: #a7a7a7;

  --toolBarSpacer: #dadce0;

  --dropDownBorder: transparent;
  --listDivider: #dadce0;

  --toolColor: var(--blue);
  --textInputBack: #eef4fc;
  --activeHighlight: var(--blue);
  --default-font-weight: 500;
  --border: var(--listDivider); /*rgb(217, 217, 217);*/
  --bg: hsl(210, 17%, 98%);
  --secondaryBg: white;
  --appBar: #ffffff;
  --appBarShadow: var(--border);
  /* --brightText: #152935; */
  /* --secondaryText: rgb(90, 104, 114); */
  /* --detailText: rgba(90, 104, 114, 0.5); */
  --brightText: rgba(0, 0, 0, 1);
  --secondaryText: rgba(0, 0, 0, 0.87);
  --detailText: rgba(0, 0, 0, 0.53);
  --disabledText: rgba(0, 0, 0, 0.2);
  --ultraDim: rgba(0, 0, 0, 0.08);
  --highlight: #eef4fc;

  --progressBg: rgb(224, 224, 224);
}

/* global css styles */

pre {
  color: var(--form-text);
  font-family: 'ibm-plex-mono', 'Lucida Console', Monaco, monospace;
  font-size: 95%;
  line-height: 1.45;
  overflow: auto;
  padding: 1rem;
  word-wrap: normal;

  border-radius: 0.4rem;
  background-color: var(--form-bg);
}

pre code {
  background-color: transparent;
  border: 0;
  display: inline;
  font-size: 100%;
  line-height: inherit;
  margin: 0;
  max-width: auto;
  overflow: visible;
  padding: 0;
  white-space: pre;
  word-break: normal;
  word-wrap: normal;
}

* {
  box-sizing: border-box;
}

a:hover {
  cursor: pointer;
}

/* table */
.bx--data-table-v2 tr:hover {
  cursor: pointer;
}

.__pageHorizontalPadding__ {
  padding-left: var(--pageHorizontalPadding);
  padding-right: var(--pageHorizontalPadding);
}

/* CHECKBOX STUFF */
.bx--checkbox-label {
  padding-left: 1.8rem;
}

.bx--checkbox-label::before {
  background-color: transparent;
  border-color: var(--brightText);
  top: calc(50% - 11px);
  border-width: 1px;
}

.bx--checkbox-label::after {
  top: calc(50% - 2px);
  border-width: 1.5px;
}

.bx--checkbox:checked + .bx--checkbox-label::before {
  background-color: var(--brightText);
  border-color: var(--brightText);
}

.bx--checkbox:checked + .bx--checkbox-label::after {
  border-color: var(--darkBg);
}
/* ^^ CHECKBOX STUFF ^^ */

@media screen and (max-width: 1500px) {
  body {
    --pageHorizontalPadding: 20px;
  }
}

@media screen and (max-width: 1000px) {
  body {
    --pageHorizontalPadding: 6px;
  }

  .__wrapper__ {
    padding-left: calc(15px);
    padding-right: calc(15px);
  }
}

@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3;
  }

  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1;
  }

  28% {
    width: 100%;
    left: auto;
    right: 0;
  }

  51% {
    width: 0%;
    left: auto;
    right: 0;
  }

  58% {
    width: 0%;
    left: auto;
    right: 0;
  }

  82% {
    width: 100%;
    left: auto;
    right: 0;
  }

  83% {
    width: 100%;
    left: 0;
    right: auto;
  }

  96% {
    width: 0%;
    left: 0;
    right: auto;
  }

  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3;
  }
}

@font-face {
  font-family: 'ibm-plex-mono';
  font-style: normal;
  font-weight: 300;
  src: url('https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexMono-Light.woff')
    format('woff');
}

@font-face {
  font-family: 'ibm-plex-mono';
  font-style: normal;
  font-weight: 300;
  src: url('https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexMono-Light-Pi.woff2')
    format('woff2');
  unicode-range: 'U+03C0, U+0E3F, U+2070, U+2074-2079, U+2080-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1, U+EBE3-EBE4, U+EBE6-EBE7, U+ECE0, U+EFCC';
}

@font-face {
  font-family: 'ibm-plex-mono';
  font-style: normal;
  font-weight: 300;
  src: url('https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexMono-Light-Latin3.woff2')
    format('woff2');
  unicode-range: 'U+0102-0103, U+1EA0-1EF9, U+20AB';
}

@font-face {
  font-family: 'ibm-plex-mono';
  font-style: normal;
  font-weight: 300;
  src: url('https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexMono-Light-Latin2.woff2')
    format('woff2');
  unicode-range: 'U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02';
}

@font-face {
  font-family: 'ibm-plex-mono';
  font-style: normal;
  font-weight: 300;
  src: url('https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexMono-Light-Latin1.woff2')
    format('woff2');
  unicode-range: 'U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+20AC, U+2122, U+2212, U+FB01-FB02';
}

@font-face {
  font-family: 'ibm-plex-mono';
  font-style: normal;
  font-weight: 400;
  src: url('https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexMono-Regular.woff')
    format('woff');
}

@font-face {
  font-family: 'ibm-plex-mono';
  font-style: normal;
  font-weight: 400;
  src: url('https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexMono-Regular-Pi.woff2')
    format('woff2');
  unicode-range: 'U+03C0, U+0E3F, U+2070, U+2074-2079, U+2080-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1, U+EBE3-EBE4, U+EBE6-EBE7, U+ECE0, U+EFCC';
}

@font-face {
  font-family: 'ibm-plex-mono';
  font-style: normal;
  font-weight: 400;
  src: url('https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexMono-Regular-Latin3.woff2')
    format('woff2');
  unicode-range: 'U+0102-0103, U+1EA0-1EF9, U+20AB';
}

@font-face {
  font-family: 'ibm-plex-mono';
  font-style: normal;
  font-weight: 400;
  src: url('https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexMono-Regular-Latin2.woff2')
    format('woff2');
  unicode-range: 'U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02';
}

@font-face {
  font-family: 'ibm-plex-mono';
  font-style: normal;
  font-weight: 400;
  src: url('https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexMono-Regular-Latin1.woff2')
    format('woff2');
  unicode-range: 'U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+20AC, U+2122, U+2212, U+FB01-FB02';
}

@font-face {
  font-family: 'ibm-plex-mono';
  font-style: normal;
  font-weight: 600;
  src: url('https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexMono-SemiBold.woff')
    format('woff');
}

@font-face {
  font-family: 'ibm-plex-mono';
  font-style: normal;
  font-weight: 600;
  src: url('https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexMono-SemiBold-Pi.woff2')
    format('woff2');
  unicode-range: 'U+03C0, U+0E3F, U+2070, U+2074-2079, U+2080-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1, U+EBE3-EBE4, U+EBE6-EBE7, U+ECE0, U+EFCC';
}

@font-face {
  font-family: 'ibm-plex-mono';
  font-style: normal;
  font-weight: 600;
  src: url('https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexMono-SemiBold-Latin3.woff2')
    format('woff2');
  unicode-range: 'U+0102-0103, U+1EA0-1EF9, U+20AB';
}

@font-face {
  font-family: 'ibm-plex-mono';
  font-style: normal;
  font-weight: 600;
  src: url('https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexMono-SemiBold-Latin2.woff2')
    format('woff2');
  unicode-range: 'U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02';
}

@font-face {
  font-family: 'ibm-plex-mono';
  font-style: normal;
  font-weight: 600;
  src: url('https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexMono-SemiBold-Latin1.woff2')
    format('woff2');
  unicode-range: 'U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+20AC, U+2122, U+2212, U+FB01-FB02';
}

@font-face {
  font-family: 'ibm-plex-sans';
  font-style: normal;
  font-weight: 300;
  src: url('https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexSans-Light.woff')
    format('woff');
}

@font-face {
  font-family: 'ibm-plex-sans';
  font-style: normal;
  font-weight: 300;
  src: url('https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexSans-Light-Pi.woff2')
    format('woff2');
  unicode-range: 'U+03C0, U+0E3F, U+2070, U+2074-2079, U+2080-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1, U+EBE3-EBE4, U+EBE6-EBE7, U+ECE0, U+EFCC';
}

@font-face {
  font-family: 'ibm-plex-sans';
  font-style: normal;
  font-weight: 300;
  src: url('https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexSans-Light-Latin3.woff2')
    format('woff2');
  unicode-range: 'U+0102-0103, U+1EA0-1EF9, U+20AB';
}

@font-face {
  font-family: 'ibm-plex-sans';
  font-style: normal;
  font-weight: 300;
  src: url('https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexSans-Light-Latin2.woff2')
    format('woff2');
  unicode-range: 'U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02';
}

@font-face {
  font-family: 'ibm-plex-sans';
  font-style: normal;
  font-weight: 300;
  src: url('https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexSans-Light-Latin1.woff2')
    format('woff2');
  unicode-range: 'U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+20AC, U+2122, U+2212, U+FB01-FB02';
}

@font-face {
  font-family: 'ibm-plex-sans';
  font-style: normal;
  font-weight: 400;
  src: url('https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexSans-Regular.woff')
    format('woff');
}

@font-face {
  font-family: 'ibm-plex-sans';
  font-style: normal;
  font-weight: 400;
  src: url('https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexSans-Regular-Pi.woff2')
    format('woff2');
  unicode-range: 'U+03C0, U+0E3F, U+2070, U+2074-2079, U+2080-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1, U+EBE3-EBE4, U+EBE6-EBE7, U+ECE0, U+EFCC';
}

@font-face {
  font-family: 'ibm-plex-sans';
  font-style: normal;
  font-weight: 400;
  src: url('https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexSans-Regular-Latin3.woff2')
    format('woff2');
  unicode-range: 'U+0102-0103, U+1EA0-1EF9, U+20AB';
}

@font-face {
  font-family: 'ibm-plex-sans';
  font-style: normal;
  font-weight: 400;
  src: url('https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexSans-Regular-Latin2.woff2')
    format('woff2');
  unicode-range: 'U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02';
}

@font-face {
  font-family: 'ibm-plex-sans';
  font-style: normal;
  font-weight: 400;
  src: url('https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexSans-Regular-Latin1.woff2')
    format('woff2');
  unicode-range: 'U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+20AC, U+2122, U+2212, U+FB01-FB02';
}

@font-face {
  font-family: 'ibm-plex-sans';
  font-style: normal;
  font-weight: 600;
  src: url('https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexSans-SemiBold.woff')
    format('woff');
}

@font-face {
  font-family: 'ibm-plex-sans';
  font-style: normal;
  font-weight: 600;
  src: url('https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexSans-SemiBold-Pi.woff2')
    format('woff2');
  unicode-range: 'U+03C0, U+0E3F, U+2070, U+2074-2079, U+2080-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1, U+EBE3-EBE4, U+EBE6-EBE7, U+ECE0, U+EFCC';
}

@font-face {
  font-family: 'ibm-plex-sans';
  font-style: normal;
  font-weight: 600;
  src: url('https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexSans-SemiBold-Latin3.woff2')
    format('woff2');
  unicode-range: 'U+0102-0103, U+1EA0-1EF9, U+20AB';
}

@font-face {
  font-family: 'ibm-plex-sans';
  font-style: normal;
  font-weight: 600;
  src: url('https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexSans-SemiBold-Latin2.woff2')
    format('woff2');
  unicode-range: 'U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02';
}

@font-face {
  font-family: 'ibm-plex-sans';
  font-style: normal;
  font-weight: 600;
  src: url('https://unpkg.com/carbon-components@latest/src/globals/fonts/IBMPlexSans-SemiBold-Latin1.woff2')
    format('woff2');
  unicode-range: 'U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+20AC, U+2122, U+2212, U+FB01-FB02';
}

@font-face {
  font-family: 'ibm-plex-sans';
  font-style: normal;
  font-weight: 500;
  src: url('https://raw.githubusercontent.com/IBM/plex/master/IBM-Plex-Sans/fonts/complete/woff/IBMPlexSans-Medium.woff')
    format('woff');
}

@font-face {
  font-family: 'ibm-plex-sans';
  font-style: italic;
  font-weight: 500;
  src: url('https://raw.githubusercontent.com/IBM/plex/master/IBM-Plex-Sans/fonts/complete/woff/IBMPlexSans-MediumItalic.woff')
    format('woff');
}
