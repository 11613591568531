a {
  color: #78a9ff;
  text-decoration: none;
}

a:hover {
  color: #a6c8ff;
  text-decoration: underline;
}

.bx--modal-container {
  width: 100%;
}

.bx--data-table-v2 th:hover .bx--table-sort-v2__icon {
  opacity: 0.5;
}

.bx--table-sort-v2--active .bx--table-sort-v2__icon {
  opacity: 1 !important;
}

/* Table */
.bx--data-table-v2-container {
  padding-top: 0px;
}

.bx--tooltip__label	{
  color: #fff;
  font-size: small;
}

.bx--data-table-v2 tbody {
  background-color: var(--secondaryBg) !important;
}

.bx--data-table-v2 th:last-of-type,
.bx--data-table-v2 td:last-of-type {
  border-right: 1px solid var(--border) !important;
}

.bx--data-table-v2 th,
.bx--data-table-v2 td {
  border-top: 1px solid var(--highlight) !important;
}

.bx--data-table-v2 th:first-of-type,
.bx--data-table-v2 td:first-of-type {
  border-left: 1px solid var(--border) !important;
}

.bx--data-table-v2 th,
.bx--data-table-v2 td {
  color: var(--secondaryText) !important;
}

.bx--data-table-v2 {
  border-bottom: 1px solid var(--border) !important;
}

.bx--data-table-v2 thead th {
  border-top: 1px solid var(--border) !important;
  border-bottom: 1px solid var(--appBarShadow) !important;
}

/* Table hover item */
.bx--data-table-v2 tr:hover td {
  color: var(--brightText) !important;
  background-color: var(--highlight) !important;
  border-top: 1px solid transparent !important;
  border-bottom: 1px solid transparent !important;
}

.bx--data-table-v2 tr:last-of-type:hover td {
  border-bottom: 1px solid var(--border) !important;
}

.bx--data-table-v2 tr:hover td:first-of-type {
  border-left: 1px solid var(--border) !important;
}

.bx--data-table-v2 tr:hover td:last-of-type {
  border-right: 1px solid var(--border) !important;
}

.bx--data-table-v2 thead {
  background-color: var(--appBar) !important;
}

.bx--data-table-v2.bx--skeleton th span,
.bx--data-table-v2.bx--skeleton td span {
  background: var(--skeleton);
}
.bx--data-table-v2.bx--skeleton th span:before,
.bx--data-table-v2.bx--skeleton td span:before {
  background: var(--skeleton);
}

.bx--table-sort-v2 {
  color: var(--brightText) !important;
}

.bx--table-sort-v2--active .bx--table-sort-v2__icon {
  opacity: 1 !important;
}

.bx--table-sort-v2:focus svg {
  opacity: 0;
  outline: none;
  fill: var(--blue);
}

/* Select input */
.bx--select-input {
  background-color: var(--textInput);
  color: var(--brightText);
  box-shadow: 0 1px 0 0 var(--textInputUnderline);
}

.bx--select-input:focus {
  box-shadow: 0 0 0 2px var(--blue);
}

.bx--select__arrow {
  fill: var(--brightText);
}

.bx--text-input[data-invalid='true'] {
  animation-name: autofillFocus;
  animation-fill-mode: both;
}

@keyframes autofillFocus {
  to {
    outline: none;
    background-color: var(--textInput);
    color: var(--brightText);
    box-shadow: 0 0 0 2px var(--error);
  }
}

/* Link */

/* a,
a:visited {
  color: var(--blue);
  text-decoration: none;
}

a:hover {
  box-shadow: 0 1px var(--blue);
}

a:active {
  color: var(--brightText);
}

a:focus {
  color: var(--brightText);
  box-shadow: 0 3px var(--brightText);
} */

.small-loader {
  width: 5.5rem;
  height: 5.5rem;
}

.small-loader svg {
  stroke: rgb(69 137 255);
}

.ca--header {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 12px;
  margin-top: 27px;
}
